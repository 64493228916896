import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div(({ overrideStyles }: { overrideStyles?: string }) => {
    return `
    position: relative;
    width: 100%;
    border-radius: 1em;
    box-shadow: 0 .6em 1em #484a521a;
    cursor: default;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;

    .instalments {
        margin: 0.2em auto 0.3em auto;
    }

    .visible-on-mobile {
        display: none;
    }

    .visible-on-desktop {
        margin: 0 0 0.5em 0;
    }

    .image-container {
        position: relative;
        width: 100%;
        min-height: 137px;
        overflow: hidden;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;

        .offer-image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 100%;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            left: 50%;
            top: 50%;
            transform: scale(1.3) translate(-50%, -50%);
            transform-origin: top left;
            transition: .3s transform ease-in-out;
        }

        .cover {
            background-size: cover;
        }

        @media (pointer: fine) {
            &:hover {
                .offer-image {
                    transform: scale(1.5) translate(-50%, -50%);
                }
            }
        }
    }

    .flex-col {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        height: 100%;

        .offer-description {
            margin-top: .5em;
            min-height: 44px;
            font-size: 80%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            flex-grow: 1;

            * {
                &.layout-html-styled-content {
                    p {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        font-size: 13px;
                        text-align: left;
                        opacity: 0.65;                    
                    }
                }
            }
        }

        .offer-pricing {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            
            .column {
                font-size: 12px;
                display: flex;
                flex-direction: column;
                width: 100%;
                color: #9c9c9c;
                
                .offer-duration-container {
                }
                
                .last-month-price-container {
                    display: flex;
                    align-items: baseline;
                    margin: 0 0 0.5em 0;
                    font-size: 12px;
                    
                    .info-mark {
                        position: relative;
                        width: 14px;
                        height: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #9c9c9c;
                        border-radius: 14px;
                        margin-right: 4px;
                        font-size: 10px;
                        
                        &:after {
                            position: absolute;
                            content: '';
                            left: -50%;
                            top: -50%;
                            width: 200%;
                            height: 200%;
                            background-color: transparent;;
                        }
                        
                        &:hover {
                            .info-mark__information {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                        
                        &__information {
                            position: absolute;
                            bottom: calc(100% + 6px);
                            left: 0;
                            width: 240px;
                            background-color: white;
                            box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.15);
                            padding: 6px;
                            border-radius: 12px 12px 12px 0;
                            color: black;
                            opacity: 0;
                            pointer-events: none;
                            transform: translateY(4px);
                            transition: opacity 200ms ease, transform 200ms ease;
                        }
                    }
                }
                
                span {
                    font-weight: 700;
                    margin-left: 4px;
                    height: max-content;
                }
            }

            .row {
                display: flex;
                width: 100%;

                .price-data-container {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    width: 100%;
                    margin: 0 0 0.5em 0;

                    .prices-container {
                        display: flex;
                        width: 100%;

                        .pricing-price {
                            font-size: 1.2em;
                            font-weight: 700;
                            color: #3B3C43;
                        }

                        .pricing-old-price {
                            font-size: 0.8em;
                            margin-left: 1em;
                            font-weight: 700;
                            color: #646464;
                            text-decoration: line-through;
                            display: flex;
                            align-items: flex-end;
                            padding-bottom: 2px;
                        }
                    }

                    .discount {
                        position: absolute;
                        top: -1em;
                        right: 1em;
                        color: white;
                        font-weight: 700;
                        padding: 3px 6px;
                        border-radius: 10px;
                        font-size: 20px;
                        min-width: 75px;
                        min-height: 33px;
                        display: flex;
                        place-content: center;
                        place-items:center;
                        background: transparent linear-gradient(277deg, #819367 0%, #9AAA79 44%, #B8D17E 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 0px 1px 10px #00000036;
                    }
                }
            }
        }
    }

    .offer-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.3em;
        height: 100%;

        .clickable {
            cursor: pointer;
            text-decoration: none;
            color: #262626;
        }

        .offer-name {
            font-weight: 400;
            font-size: 20px;
        }

        .box-control {
            margin-top: 1em;
            text-decoration: none;
            color: #BA9449;
        }

        .layout-forms-select {
            padding-top: 1em;
        }

        .price-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            margin: 0.5em 0 0 0;
            letter-spacing: 0.13px;

            &__gold {
                color: ${variables.colorGold};
            }

            &__grey {
                color: ${variables.colorTextGray};
            }

            &__bigger {
                margin-left: 0.3em;
                font-size: 17px;
                font-weight: 700;
            }
        }
    }

    .link-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .pricing-controls {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1em;

        .public-layout-offer-buy-now-button,
        .public-layout-offer-add-to-cart-button {
            width: 50%;
        }

        &.one-button {
            .public-layout-offer-buy-now-button,
            .public-layout-offer-add-to-cart-button {
                width: 100%;
                justify-content: center;
                gap: 0.5em;
            }
        }
    }

    .check-button {
        min-width: 100%;

        .button {
            width: 100%;
        }
    }

    .checkbox-add-max {
        position: relative;

        .checkbox-add-max-info {
            position: absolute;
            top: -5px;
            right: 0;
            color: #787878;

            .tooltip-text {
                max-width: 400px;
            }
        }
    }

    @media all and (max-width: ${variables.tabletL}) {

        .visible-on-mobile {
            display: block;
        }
    
        .visible-on-desktop {
            display: none;
        }

        .offer-description {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        .image-container {
            min-height: 125px;

            .read-more {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .offer-content {
            font-size: 120%;

            .offer-name {
                font-size: 0.9em;
            }

            .flex-col {
                .offer-description {
                    min-height: unset;
                    font-size: 95%;
                }
            }

            .price-info {
                font-size: 11px;
                margin: 0.5em 0 0 0;
    
                &__gold {
                    color: ${variables.colorGold};
                }
    
                &__grey {
                    color: ${variables.colorTextGray};
                }
    
                &__bigger {
                    margin-left: 2px;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        .offer-content {
            padding: 1.1em;

            .offer-name {
                font-size: 17px;
                font-weight: 500;
            }

            .flex-col {
                .offer-pricing {
                    .column {
                        font-size: 10px;
                        color: #9c9c9c;
                        
                        .offer-duration-container {}
                        .last-month-price-container {
                            flex-wrap: wrap;
                                .info-mark {
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 12px;
                                    font-size: 6px;
                                    
                                    &__information {
                                        font-size: 10px;
                                        width: 200px;
                                    }
                                }
                        }
                    }
                }

                .row {
    
                    .price-data-container {
                        .prices-container {
                            .pricing-price {
                                font-size: 20px;
                                font-weight: 700;
                            }
    
                            .pricing-old-price {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }          
        }

        .checkbox-add-max {
            .checkbox-add-max-info {
                .tooltip-text {
                    max-width: 240px;
                    min-width: 200px;
                    font-size: 0.9em;
                }
            }
        }
    }
    ${overrideStyles}
    `;
});
