import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApiResponseBody, ReduxRequestPassOptions } from 'types/request';

import Cart from 'models/Cart';
import Product from 'models/Product';

import { createCartItem, CreateCartItemParams } from 'store/modules/carts/actions';
import { State } from 'store/state';

import Component from './component';

export interface Props extends ParentProps {
    actions: {
        createCartItem: (params?: CreateCartItemParams, requestParams?: ReduxRequestPassOptions) => Promise<ApiResponseBody>;
    };
    state: {
        cart: Cart;
    }
}
export interface ParentProps {
    product: Product;
    control: {
        link: {
            label: string;
        };
        buyNow: {
            label: string;
        };
        addToCart: {
            label: string;
        };
    }
    input: {
        quantity: {
            placeholder?: string;
        }
    }
    locationId?: string;
    section: string
    isBuyNowDisabled?: boolean;
    isAddToCartDisabled?: boolean;
    isCheckButtonVisible?: boolean;
}

const mapStateToProps = (state: State): object => {
    return {
        state: {
            cart: state?.cart?.data,
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            createCartItem: bindActionCreators(createCartItem, dispatch),
        },
    };
};

Component.defaultProps = {
    isCheckButtonVisible: false,
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
